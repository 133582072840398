import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Hero from '../../components/hero'
import PageSlider from '../../containers/page-slider'
import SplitBlock from '../../containers/theme/split-block'
import { truncateString, slugify } from '../../utils/utilFunctions'

import ArbeidsmarktIcon from '../../assets/images/icons/arbeidsmarkt.svg'
import EconomieIcon from '../../assets/images/icons/economie.svg'
import MobiliteitIcon from '../../assets/images/icons/mobiliteit.svg'
import RuimteIcon from '../../assets/images/icons/ruimte.svg'
import WonenIcon from '../../assets/images/icons/wonen.svg'
import AlgemeenIcon from '../../assets/images/icons/algemeen.svg'

const IndexPage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query MWBPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "4d85f8fd-1a8b-54bb-bc7b-2e02e6a6f6ec" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            subpages {
              ... on Node {
                ... on ContentfulPagina {
                  id
                  name
                  headerTitel
                  headerSubtitel
                  subpages {
                    ... on Node {
                      ... on ContentfulPagina {
                        id
                        name
                        headerTitel
                      }
                      ... on ContentfulWebsite {
                        id
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const getIcon = (title) => {
    const icons = {
      Arbeidsmarkt: ArbeidsmarktIcon,
      Economie: EconomieIcon,
      Mobiliteit: MobiliteitIcon,
      Ruimte: RuimteIcon,
      Wonen: WonenIcon,
      Algemeen: AlgemeenIcon,
    }

    return icons[title] ? { file: { url: icons[title] } } : undefined
  }

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/dataloket-west-brabant`
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
    subpages,
  } = pageData.page.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const pageList = subpages
    .filter((page) => page.name)
    .map((page) => {
      page.title = page.headerTitel
      page.description = page.headerSubtitel
        ? truncateString(page.headerSubtitel, 60)
        : ''
      page.longDescription = page.headerSubtitel
      page.url = `/actueel/dataloket-west-brabant/${slugify(page.name)}`
      page.btnText = `${page.name}`
      page.subthemes = page.subpages.map((subpage) => ({
        id: subpage.id,
        title: subpage.headerTitel || subpage.title,
        slug: subpage.name ? slugify(subpage.name) : null,
        url: subpage.url,
        themes: [
          {
            id: page.id,
          },
        ],
      }))

      return page
    })

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
          showLastLable={false}
        />

        <PageSlider list={pageList} offset={true} />

        {pageList.map(
          ({ id, title, longDescription, url, subthemes }, index) => (
            <SplitBlock
              index={index}
              key={id}
              identifier={id}
              title={title}
              description={longDescription}
              url={url}
              layout={index % 2}
              subthemes={subthemes}
              icon={getIcon(title)}
              btnText="Lees meer"
            />
          ),
        )}
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
